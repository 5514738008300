@import '../../../../scss/theme-bootstrap';

// Added for 2018 Creative refresh, loads for product_review_snippet_v1
.product-review-snippet {
  display: table;
  &__stars {
    display: table-cell;
    height: 20px;
    line-height: 20px;
  }
  // specificity is intentional - we rely on some older styles for this
  .product__rating-total,
  .product__rating-avg-rating,
  .product__rating-no-results {
    display: table-cell;
    font-size: 11px;
    height: 20px;
    line-height: 1.8;
    @include swap_direction(padding, 0 0 0 0.5em);
  }
  .product__rating-count,
  .product__rating-average,
  .product__rating-no-results {
    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
  }
}

.product-full--enhanced {
  .product-full__rating {
    padding-top: 0;
    @include breakpoint($bp--medium-down) {
      padding-bottom: 11px;
    }
    .product-review-snippet {
      display: flex;
      .product__rating-total {
        display: inline-flex;
        font-size: 12px;
        line-height: 1.8;
      }
    }
  }
}
